@import "tailwindcss/base"
@import "tailwindcss/components"
@import "tailwindcss/utilities"
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Arapey&family=Kaisei+Opti&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:wght@200;300;400;600;800&display=swap')
*
  @apply box-border

html
  @apply scroll-smooth

body
  @apply p-0 m-0 font-roboto text-base text-dark-600

@layer components
  a
    @apply no-underline  underline-offset-2

  input,
  select,
  textarea,
  button
    @apply focus:outline-none bg-transparent

  .text-linear
    background-clip: text
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent

  [role="button"],
  button
    @apply select-none



.EZDrawer
  display: none

@media screen and (max-width: 767px)
  .EZDrawer
    display: block

.custom-scroll
  &::-webkit-scrollbar
    width: 6px

  &::-webkit-scrollbar-track
    background: #f1f1f1
    border-radius: 4px

  &::-webkit-scrollbar-thumb
    background: #82B1FF
    border-radius: 4px

    &:hover
      background: #555


.wordbrack
  word-break: break-all
  word-break: break-word 

.User_Operations_table td
  padding: 10px !important  
